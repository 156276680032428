<template>
  <div class="custom-appointment" v-can="'ConfConAten4'">
    <div class="breadcrumbs">
      <div>
        <router-link to="/configuracoes-da-consulta" tag="span" class="link">
          Configurações da {{ attendanceLabel }}
        </router-link>
        <ChevronRight class="chevron" />
        <router-link
          v-if="selectedForm"
          :to="`/configuracoes-da-consulta/${selectedForm.value}`"
          tag="span"
        >
          {{ selectedForm.label }}
        </router-link>
      </div>
      <div>
        <b-button
          v-can="'ConfConAten7'"
          class="wh-button"
          v-if="canDelete"
          variant="outline-danger"
          @click="onDeleteClick"
        >
          Excluir {{ attendanceLabel }}
        </b-button>
      </div>
    </div>
    <div class="attendance-container">
      <div class="content ">
        <Menu />
        <Forms />
      </div>
    </div>
  </div>
</template>

<script>
import { getFormGroups } from '@/utils/formGroups.js'
import cloneDeep from 'lodash/cloneDeep'
import { mapState, mapActions } from 'vuex'
import { getCurrentUser } from '@/utils/localStorageManager'

export default {
  metaInfo: {
    title: 'Eyecare - Configurações da consulta'
  },
  name: 'CustomAppointmentView',
  components: {
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    Menu: () => import('@/components/Attendance/Menu'),
    Forms: () => import('@/components/Attendance/Forms')
  },
  async created() {
    await this.getForm()
    this.setCustomFormEdit(true)
    this.clearAttendance()
    this.closeAttendance()
    this.menu.opened = true
  },
  computed: {
    ...mapState({
      selectedForm: state => state.attendance.form.selected,
      canEdit: state => state.attendance.canEdit,
      attendance: state => state.attendance.attendance,
      customFormEdit: state => state.attendance.customFormEdit,
      menu: state => state.attendance.menu,
    }),
    attendanceLabel() {
      return this.selectedForm?.type === 'ATTENDANCE' ? 'consulta' : 'exame'
    },
    canDelete() {
      return ['ATTENDANCE', 'EXAM'].includes(this.selectedForm?.type) && this.selectedForm?.canEditName
    }
  },
  data() {
    return {
      user: getCurrentUser(),
      formGroups: {},
      id: this.$route.params.id
    }
  },
  methods: {
    ...mapActions('attendance/form', ['setAttendanceSelectForm']),
    ...mapActions('attendance', ['closeAttendance', 'setCustomFormEdit', 'clearAttendance', 'editDefaultForms']),
    userHasPermission(form) {
      if (form.type === 'ATTENDANCE') {
        return this.user.id === form.professional_id
      } else if (['PRE_CONSULTATION', 'EXAM', 'SURGERY'].includes(form.type)) {
        return this.user.clinics.find(clinic => {
          return clinic.id === form.clinic_id
        })
      }
    },
    async getForm() {
      const loading = this.$loading.show()
      this.editDefaultForms()
      try {
        const { data } = await this.api.getForm(this.id)
        if (!this.userHasPermission(data)) {
          this.$toast.warning(
            'Você não possui permissão para realizar esta ação!'
            )
            this.$router.push('/')
        }
        const form = data
        const formsData = form.forms.map((form) => {
          return {
            ...form,
            customName: form?.customName ?? form?.name,
          }
        })

        this.setAttendanceSelectForm({ 
          label: form.name, 
          value: form.id, 
          forms: formsData,
          canEditName: form.can_edit_name,
          type: form.type,
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    async saveForm(form, showing) {
      const loading = this.$loading.show()
      try {
        const total = []
        const newFormGroups = cloneDeep(this.formGroups, {}, true)

        Object.keys(newFormGroups).forEach((key, index) => {
          Object.values(newFormGroups[key].forms).forEach(f => {
            if (form && f.id === form.id) {
              f.showing = showing
              f.opened = showing

              if (!f.id.includes('exame-complementar')) {
                delete f.fields
              }
            }
            delete f.value
            delete f.editingIndex
            f.active = false

            total.push(f)
          })
        })

        const { data } = await this.api.updateForm(this.id, {
          forms: total
        })
        this.form = data
        this.formGroups = getFormGroups(data)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    async changeFormName(value) {
      try {
        const { data } = await this.api.updateForm(this.id, {
          name: value
        })
        this.form = data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onDeleteClick() {
      this.$bvModal
        .msgBoxConfirm(
          `Você tem certeza que deseja excluir a ${this.attendanceLabel} ${this.selectedForm.label}? 
          Não será possível reverter essa ação.`,
          {
            title: `Excluir ${this.attendanceLabel}`,
            size: 'md',
            okTitle: 'Confirmar',
            cancelTitle: 'Cancelar',
            cancelVariant: 'danger',
            centered: true
          }
        )
        .then(confirm => {
          confirm && this.deleteForm()
        })
    },
    async deleteForm() {
      try {
        await this.api.deleteForm(this.id)
        this.$toast.success('Formulário excluído com sucesso!')
        this.$router.push('/configuracoes-da-consulta')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    goTo(form) {
      Object.keys(this.formGroups).forEach((key, index) => {
        Object.values(this.formGroups[key].forms).forEach(f => {
          if (f.id !== form.id) {
            f.active = false
          }
        })
      })
      function scrollTo(parentElement, element, to, duration) {
        const start = element.scrollTop
        const change = to.offsetTop - parentElement.scrollTop - start
        const increment = 20

        let currentTime = 0

        var animateScroll = function () {
          currentTime += increment
          const val = Math.easeInOutQuad(currentTime, start, change, duration)
          element.scrollTop = val
          if (currentTime < duration) {
            setTimeout(animateScroll, increment)
          }
        }
        animateScroll()
      }

      Math.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2
        if (t < 1) return (c / 2) * t * t + b
        t--
        return (-c / 2) * (t * (t - 2) - 1) + b
      }

      if (!this.$refs[form.id]) {
        form.showing = true
      }

      try {
        setTimeout(
          () => {
            scrollTo(
              this.$parent.$refs.mainScroll,
              this.$refs.scroll.$el,
              this.$refs[form.id][0].$el,
              1000
            )
          },
          !this.$refs[form.id] ? 1000 : 0
        )
      } catch (error) {
        console.log(error)
      }
    },
    async addCustom() {
      if (!this.formGroups['Exames complementares']) {
        this.formGroups['Exames complementares'] = {
          opened: true,
          forms: {}
        }
      }

      const customId = 'exame-personalizado-' + new Date().getTime()
      const form = {
        id: customId,
        name: 'Exame personalizado',
        active: true,
        showing: true,
        opened: true,
        editing: false,
        fields: {
          olhoDireito: null,
          olhoEsquerdo: null
        }
      }
      this.formGroups['Exames complementares'].forms[customId] = form
      this.$forceUpdate()
      await this.saveForm()

      this.$nextTick(() => {
        this.goTo(form)
      })
    },
    async editForm(form) {
      form.editing = !form.editing

      if (!form.editing) {
        this.formGroups['Exames complementares'].forms[form.id] = form
        await this.saveForm()
      }

      this.$nextTick(() => {
        this.$refs[form.id][0].$el.querySelector('input').focus()
      })
    },
    async onBlurForm(formId) {
      const groupKey = Object.keys(this.formGroups).find(key =>
        Object.keys(this.formGroups[key].forms).includes(formId)
      )
      if (groupKey) {
        const form = this.formGroups[groupKey].forms[formId]
        await this.saveForm(form)
      }
    },
  },
  async beforeDestroy(to, from) {
    this.setCustomFormEdit(false)
  },
}
</script>

<style lang="scss" scoped>
.custom-appointment {
  width: 100%;
  height: auto;
  padding: 30px 24px;
  text-align: left;
  display: inline-block;

  .breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    button {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .attendance-container {
    height: calc(100vh - 170px);
    .content {
      display: flex;
      height: 100%;
    }
  }
}
</style>
